import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import moment from 'moment';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { authStore, calendarShiftPlanningStore, shiftPlanningTempsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { DATE_WITHOUT_TIME_FORMAT, DATE_WITHOUT_TIME_FORMAT_ISO } from '@/utils/moment';
import { buildDropdownOptionsFromEnum } from '@/utils/common';
import { SIDEBAR_WIDTH } from '@/constants';
import { startDateAfterNow } from '@/utils/form-rules';

import { disabledDate } from './setup';
import { GenerateEventsTemporaryVersion } from '@/types/enums';

const entity = 'coordinator-temps-shift-planning';

const getFormFields = (hasAdminRole) => [
  {
    name: 'version',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.version',
      options: buildDropdownOptionsFromEnum(GenerateEventsTemporaryVersion, 'shiftPlanning.version.'),
      disabled: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'buildingId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.building',
      options: [{ value: 'all', label: getTranslatedString('shiftPlanning.auto-create.building.all') }],
      disabled: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'dates',
    componentName: 'date-range-picker',
    params: {
      i18nLabel: 'shiftPlanning.eventRange',
      format: DATE_WITHOUT_TIME_FORMAT,
      disabledDate: !hasAdminRole && disabledDate,
    },
    rules: [!hasAdminRole && startDateAfterNow(), { required: true }].filter(Boolean),
  },
];


export const AutoCreateExtended = observer(({ transition }: UIViewInjectedProps) => {
  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };
  const currentView = transition.router.globals.params.view;

  const initValues = {
    version: GenerateEventsTemporaryVersion.OneShiftsEventsPerDay,
    buildingId: 'all',
    dates: [moment().startOf('month'), moment().endOf('month')],
  };

  const title = getTranslatedString('shiftPlanning.auto-create-new.extended');

  const formFields = getFormFields(authStore.hasAdminRole());
  const onSubmit = ({ dates }) => {
    const data = {
      start: dates[0].format(DATE_WITHOUT_TIME_FORMAT_ISO),
      end: dates[1].format(DATE_WITHOUT_TIME_FORMAT_ISO),
    };

    return api.shiftPlanningTemps.generateEventsShiftPlanning(data).source.then(() => {
      if (currentView === 'calendar') {
        return calendarShiftPlanningStore.refresh();
      }
      return shiftPlanningTempsStore.refresh();
    });
  };

  const resourceController = withoutRepeatValue(initValues, {
    create: onSubmit,
  });

  return (
    <DrawerForm
      title={title}
      initValues={initValues}
      formFields={formFields}
      resourceController={resourceController}
      loaderCondition={false}
      onClose={onBack}
      initialDisableSubmitValue={false}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
